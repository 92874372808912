jQuery(document).ready(function ($) {
  //Nav Functionality - Start
  $('.nav-primary .nav-toggle').click(function (e) {
    var overlay = $('<div class="nav-items-wrapper"></div>');
    var container = $('<div class="container"></div>');

    $('body').wrapInner(container);
    $('body').append(overlay).addClass('open');
    $('.nav-items').contents().appendTo('.nav-items-wrapper');
    $('.nav-items-wrapper').addClass('active').animate({
      top: 0
    });
    $('.nav-items-wrapper.active .close').first().focus();
    $('.nav-items-wrapper .close, .nav-items-wrapper a').click(function () {
      $('.nav-items-wrapper').animate({
        top: '100%'
      });
      setTimeout(function () {
        $('.nav-items-wrapper').contents().appendTo('.nav-wrapper .nav-primary .nav-items');
        $('.nav-items-wrapper').remove();
        $("body .container").contents().unwrap();
      }, 500);
      $('body').removeClass('open');
    });


    // find focusable items
    var focusable = $('body .nav-items-wrapper.active .ul1 .li1 .a1, body .nav-items-wrapper.active .close');
    var focusableFirst = focusable.first();
    var focusableLast = focusable.last();
    focusableLast.on('keydown', function (e) {
      if ((e.which === 9 && !e.shiftKey)) {
        e.preventDefault();
        focusableFirst.focus();
      }
    });
    focusableFirst.on('keydown', function (e) {
      if ((e.which === 9 && e.shiftKey)) {
        e.preventDefault();
        focusableLast.focus();
      }
    });
  });

  //On Enter key
  $('.nav-primary .nav-toggle').keypress(function (e) {
    var overlay = $('<div class="nav-items-wrapper"></div>');
    var container = $('<div class="container"></div>');
    if (e.which == 13) {

      $('body').wrapInner(container);
      $('body').append(overlay).addClass('open');
      $('.nav-items').contents().appendTo('.nav-items-wrapper');
      $('.nav-items-wrapper').addClass('active').animate({
        top: 0
      });
      $('.nav-items-wrapper.active .close').first().focus();
      $('.nav-items-wrapper .close, .nav-items-wrapper a').click(function () {
        $('.nav-items-wrapper').animate({
          top: '100%'
        });
        setTimeout(function () {
          $('.nav-items-wrapper').contents().appendTo('.nav-wrapper .nav-primary .nav-items');
          $('.nav-items-wrapper').remove();
          $("body .container").contents().unwrap();
        }, 500);
        $('body').removeClass('open');
      });
      $('.nav-items-wrapper .close, .nav-items-wrapper a').keypress(function (e) {
        if (e.which == 13) {
          $('.nav-items-wrapper').animate({
            top: '100%'
          });
          setTimeout(function () {
            $('.nav-items-wrapper').contents().appendTo('.nav-wrapper .nav-primary .nav-items');
            $('.nav-items-wrapper').remove();
            $("body .container").contents().unwrap();
          }, 500);
          $('body').removeClass('open');
        }
      });
    }

    // find focusable items
    var focusable = $('body .nav-items-wrapper.active ul li a, body .nav-items-wrapper.active .close');
    var focusableFirst = focusable.first();
    var focusableLast = focusable.last();
    focusableLast.on('keydown', function (e) {
      if ((e.which === 9 && !e.shiftKey)) {
        e.preventDefault();
        focusableFirst.focus();
      }
    });
    focusableFirst.on('keydown', function (e) {
      if ((e.which === 9 && e.shiftKey)) {
        e.preventDefault();
        focusableLast.focus();
      }
    });
  });

  //Sticky Nav
  $(window).scroll(function () {
    if ($(this).scrollTop() > 150) {
      $('header').addClass("sticky");
    } else {
      $('header').removeClass("sticky");
    }
  });

  // primary nav
  $('.nav-primary').accessibleNav({
    spans: 'hide',
    level2position: 'vertical-bottom',
    level3position: 'horizontal-right'
  });
  //Nav Functionality - End

  //Search Functionality - Start
  // $('.search-toggle').click(function () {
  //   // $('body').addClass('open');
  //   $('.search-box').addClass('active').animate({
  //     top: 0
  //   });
  //   $('.search-box input').first().focus();
  //   $('.search-box .fa-times').click(function () {
  //     $('.search-box').animate({
  //       top: '100%'
  //     });
  //     // $('.search-box').removeClass('active');
  //     // $('body').removeClass('open');
  //   });
  $('.search-toggle').click(function (e) {
    var searchoverlay = $('<div class="search-box-wrapper"></div>');
    var container = $('<div class="container"></div>');

    $('body').wrapInner(container);
    $('body').append(searchoverlay).addClass('open');
    $('.search-box').contents().appendTo('.search-box-wrapper');
    $('.search-box-wrapper').addClass('active').animate({
      top: 0
    });
    $('.search-box-wrapper.active input').first().focus();
    $('.search-box-wrapper .fa-times').click(function () {
      $('.search-box-wrapper').animate({
        top: '100%'
      });
      setTimeout(function () {
        $('.search-box-wrapper').contents().appendTo('.search-box');
        $('.search-box-wrapper').remove();
        $("body .container").contents().unwrap();
      }, 500);
      $('body').removeClass('open');
    });

    // find focusable items
    var searchFocusable = $('.search-box-wrapper span.fas.fa-times, .search-box-wrapper .search-container a.btn-search, .search-box-wrapper .search-container input');
    var searchFocusableFirst = searchFocusable.first();
    var searchFocusableLast = searchFocusable.last();
    searchFocusableLast.on('keydown', function (e) {
      if ((e.which === 9 && !e.shiftKey)) {
        e.preventDefault();
        searchFocusableFirst.focus();
      }
    });
    searchFocusableFirst.on('keydown', function (e) {
      if ((e.which === 9 && e.shiftKey)) {
        e.preventDefault();
        searchFocusableLast.focus();
      }
    });
  });

  //On Enter key
  $('.search-toggle').keypress(function (e) {
    var searchoverlay = $('<div class="search-box-wrapper"></div>');
    var container = $('<div class="container"></div>');
    if (e.which == 13) {
      $('body').wrapInner(container);
      $('body').append(searchoverlay).addClass('open');
      $('.search-box').contents().appendTo('.search-box-wrapper');
      $('.search-box-wrapper').addClass('active').animate({
        top: 0
      });
      $('.search-box-wrapper.active input').first().focus();
      $('.search-box-wrapper .fa-times').click(function () {
        $('.search-box-wrapper').animate({
          top: '100%'
        });
        setTimeout(function () {
          $('.search-box-wrapper').contents().appendTo('.search-box');
          $('.search-box-wrapper').remove();
          $("body .container").contents().unwrap();
        }, 500);
        $('body').removeClass('open');
      });
      $('.search-box-wrapper .fa-times').keypress(function (e) {
        if (e.which == 13) {
          $('.search-box-wrapper').animate({
            top: '100%'
          });
          setTimeout(function () {
            $('.search-box-wrapper').contents().appendTo('.search-box');
            $('.search-box-wrapper').remove();
            $("body .container").contents().unwrap();
          }, 500);
          $('body').removeClass('open');
        }
      });
    }

    // find focusable items
    var focusable = $('.search-box-wrapper span.fas.fa-times, .search-box-wrapper .search-container a.btn-search, .search-box-wrapper .search-container input');
    var focusableFirst = focusable.first();
    var focusableLast = focusable.last();
    focusableLast.on('keydown', function (e) {
      if ((e.which === 9 && !e.shiftKey)) {
        e.preventDefault();
        focusableFirst.focus();
      }
    });
    focusableFirst.on('keydown', function (e) {
      if ((e.which === 9 && e.shiftKey)) {
        e.preventDefault();
        focusableLast.focus();
      }
    });
  });
  //Search Functionality - End

  //Scroll to Top
  $(window).scroll(function () {
    if ($(this).scrollTop() > 250) {
      $(".scroll-to-top").css("opacity", "1");
    } else {
      $(".scroll-to-top").css("opacity", "0");
    }
  });
  $(".scroll-to-top").click(function () {
    $('html, body').animate({
      scrollTop: 0
    }, 1000);
    return false;
  });
  //Scroll to Top END

  //Smooth Scroll to Section
  // $(document).on('click', 'a[href="#main"]', function (event) {
  //   event.preventDefault();
  //   $('html, body').animate({
  //     scrollTop: $($.attr(this, 'href')).offset().top
  //   }, 1500);
  // });
  //Smooth Scroll to Section - END

  //Slide to sections on click
  $('a[href*="#"]')
    // Remove links that don't actually link to anything
    .not('[href="#"]')
    .not('[href="#0"]')
    .not('.open-modal')
    .not('[role="tab"]')
    .click(function (event) {
      // On-page links
      if (
        location.pathname.replace(/^\//, '') == this.pathname.replace(/^\//, '') &&
        location.hostname == this.hostname
      ) {
        // Figure out element to scroll to
        var target = $(this.hash);
        target = target.length ? target : $('[name=' + this.hash.slice(1) + ']');
        // Does a scroll target exist?
        if (target.length) {
          // Only prevent default if animation is actually gonna happen
          event.preventDefault();
          $('html, body').animate({
            scrollTop: target.offset().top
          }, 1500, function () {
            // Callback after animation
            // Must change focus!
            var $target = $(target);
            $target.focus();
            if ($target.is(":focus")) { // Checking if the target was focused
              return false;
            } else {
              $target.attr('tabindex', '-1'); // Adding tabindex for elements not focusable
              $target.focus(); // Set focus again
            }
          });
        }
      }
    });

  //Open PDF links in new window
  $(function () {
    $('a[href$=".pdf"]').prop('target', '_blank');
  });

  //Open external links in new window
  $(window).load(function () {
    $('a')
      .filter('[href^="http"], [href^="//"]')
      .not('[href*="' + window.location.host + '"]')
      .attr('target', '_blank');
  });


});